export const getFormattedTime = (time = '', useFullWord = false, showLeftText = true, returnValues = false) => {
  console.log('time', time);
  const splittedTime = (time || '').split(':'),
    hours = parseInt(splittedTime[0]),
    showHours = !!parseInt(splittedTime[0]),
    mins = parseInt(splittedTime[1]),
    showMins = !!parseInt(splittedTime[1]),
    hoursString = `${splittedTime[0]}${useFullWord ? '' : ' hrs'}`,
    minsString = `${splittedTime[1]}${useFullWord ? '' : ' mins'}`;

  if (returnValues) {
    return { hours, mins };
  }
  if (!showHours && !showMins) {
    return 'Completed !';
  }
  return `${hoursString}${showMins ? ',' : ''} ${showMins ? minsString : ''} ${showLeftText ? 'left' : ''}`;
};

export const removeDuplicatesFromArray = (inputArr) => {
  if (Array.isArray(inputArr)) {
    const outputArray = new Array(...new Set(inputArr.map(JSON.stringify)));
    return outputArray.map(JSON.parse);
  } else return [];
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const isValidJSON = (str) => {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};
